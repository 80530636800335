<template>
  <div>
    <div class="header-box mb-4 pt-5 pb-4">
      <div class="container" v-if="anticipationList.inProgress">
        <div class="text-center">
          <h3 class="font-weight-light">Antecipação em análise</h3>
        </div>
        <div class="text-center mx-auto mb-3">
          <h4 class="text-muted font-weight-light mb-0">Valor solicitado:</h4>
          <h1 class="font-weight-light mb-1">
            {{
              anticipationList.inProgress.amountRequested
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </h1>
          <small class="text-muted">Data da solicitação</small>
          <h6 class="font-weight-light m-0">
            {{
              anticipationList.inProgress.solicitedAt
                | moment("DD/MM/YYYY HH:mm:ss")
            }}
          </h6>
        </div>

        <WhiteLabel class="d-flex">
          <router-link
            tag="button"
            :to="{
              name: 'show-anticipation',
              params: { anticipationId: anticipationList.inProgress.id },
            }"
            class="btn btn-outline-orange btn-pill mx-auto"
            >Detalhes</router-link
          >
        </WhiteLabel>
      </div>
    </div>
    <div class="container">
      <div class="col">
        <div class="mb-4 d-none d-md-flex">
          <div class="text-center ml-auto mr-5 small-paragraph">
            <div class="color-circle bg-success mb-0 mx-auto"></div>
            <small class="font-weight-light">Aprovada</small>
          </div>
          <div class="text-center mr-5 small-paragraph">
            <div class="color-circle bg-danger mb-0 mx-auto"></div>
            <small class="font-weight-light">Reprovada</small>
          </div>
          <div class="text-center mr-auto small-paragraph">
            <div class="color-circle bg-canceled mb-0 mx-auto"></div>
            <small class="font-weight-light">
              Parcialmente
              <br />aprovada
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="(anticipation, index) of anticipationList.anticipations"
          :key="index"
        >
          <div
            class="card table-card border-badge"
            :class="statusColor(anticipation.status)"
          >
            <div class="card-content">
              <div class="d-flex mb-2">
                <div class="text-right pull-right ml-auto">
                  <h6 class="text-muted font-weight-light mb-0">
                    Valor solicitado:
                  </h6>
                  <p class="font-weight-light mb-0">
                    {{
                      anticipation.amountRequested
                        | currency("R$ ", 2, {
                          decimalSeparator: ",",
                          thousandsSeparator: ".",
                        })
                    }}
                  </p>
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="text-center mx-auto">
                  <h6 class="font-weight-light mb-0 text-muted">
                    Valor aprovado:
                  </h6>
                  <h3 class="font-weight-light mb-3">
                    {{
                      anticipation.amountApproved
                        | currency("R$ ", 2, {
                          decimalSeparator: ",",
                          thousandsSeparator: ".",
                        })
                    }}
                  </h3>
                  <router-link
                    tag="button"
                    :to="{
                      name: 'show-anticipation',
                      params: { anticipationId: anticipation.id },
                    }"
                    class="btn btn-outline-orange btn-pill"
                    >Detalhes</router-link
                  >
                </div>
              </div>
              <div class="d-flex">
                <div class="text-left mr-auto">
                  <small class="text-muted">Data da solicitação:</small>
                  <br />
                  <small>{{
                    anticipation.solicitedAt | moment("DD/MM/YYYY HH:mm:ss")
                  }}</small>
                </div>
                <div class="text-right">
                  <small class="text-muted">Data da finalização:</small>
                  <br />
                  <small>{{
                    anticipation.completedAt | moment("DD/MM/YYYY HH:mm:ss")
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="anticipationList.count === 0">
        <div class="text-center">
          <h4>Nenhuma solicitação encontrada.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="anticipationList.count"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsApi from '@/services/PaymentsApi';
import AppPaginator from '@/components/theme/Paginator';
import { AnticipationFilter, AnticipationList } from '@/models/Anticipation';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppAnticipationHistory',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getAnticipations();
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      perPage: 12,
      count: 0,
      anticipationList: new AnticipationList(),
      anticipationFilter: new AnticipationFilter(),
    };
  },
  methods: {
    async getAnticipations() {
      const api = new PaymentsApi();
      this.anticipationList = await api.anticipationHistory(
        this.anticipationFilter
      );
      if (this.anticipationList.inProgress === null) {
        this.anticipationList.inProgress = false;
      }
      this.anticipationList.anticipations =
        this.anticipationList.anticipations.slice(0, this.perPage);
    },
    statusColor(type) {
      return {
        'border-left-success': type === 'approved',
        'border-left-delayed': type === 'pending',
        'border-left-reproved': type === 'reproved',
        'border-left-canceled': type === 'partiallyApproved',
      };
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.anticipationFilter.offset.index = inicio;
      await this.getAnticipations();
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
