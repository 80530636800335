var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAccountActive)?_c('div',[(_vm.anticipationPermission.canAcceptTerm)?_c('app-term-of-service'):_vm._e(),(_vm.anticipationPermission.canAnticipate)?_c('div',[(
          (_vm.anticipation === null &&
            _vm.anticipationPermission.hasCardTransaction) ||
          (_vm.anticipation === null && !_vm.whoAmI.account.transferPlan.anticipated)
        )?_c('div',[_c('div',{staticClass:"header-box pt-5 border-0"},[_c('div',{staticClass:"row m-0"},[_c('WhiteLabel',{staticClass:"m-auto"},[_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#anticipation","data-toggle":"tab"}},[_vm._v("Antecipação")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#history","data-toggle":"tab"}},[_vm._v("Histórico")])])])])],1)]),_c('form',{staticClass:"tab-content",attrs:{"id":"formOrder","autocomplete":"off"}},[_c('div',{staticClass:"tab-pane show active",attrs:{"id":"anticipation"}},[_c('div',{attrs:{"id":"anticipation-collapse","data-parent":"#formOrder"}},[_c('app-anticipation-value',{attrs:{"permission":_vm.userAnticipationPermission}})],1)]),_c('div',{staticClass:"tab-pane",attrs:{"id":"history"}},[_c('div',{attrs:{"id":"history-collapse","data-parent":"#formOrder"}},[_c('app-anticipation-history',{attrs:{"permission":_vm.userAnticipationPermission}})],1)])])]):_vm._e()]):_vm._e(),(
        _vm.anticipation !== null ||
        (_vm.whoAmI.account.transferPlan.anticipated &&
          !_vm.anticipationPermission.hasCardTransaction)
      )?_c('div',[_c('app-anticipation-history')],1):_vm._e()],1):_vm._e(),(!_vm.isAccountActive)?_c('div',[_c('app-anticipation-history')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }