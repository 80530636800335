<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-w-full col-lg-5">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                Informações da antecipação
              </h3>
            </div>
            <hr />
            <div class="card-content">
              <div class="row">
                <div class="form-group col-12 col-w-full px-0 mb-3">
                  <label>Data da solicitação:</label>
                  <p class="mb-0">
                    {{
                      anticipation.solicitedAt | moment("DD/MM/YYYY HH:mm:ss")
                    }}
                  </p>
                </div>
                <div class="form-group col-12 col-w-full px-0 mb-4">
                  <label>Status:</label> <br />
                  <div class="d-flex">
                    <div class="mr-2">
                      <span
                        class="badge badge-pill"
                        :class="statusColor(anticipation.status)"
                      >
                        <p class="mb-0 text-uppercase">
                          {{ statusName(anticipation.status) }}
                        </p>
                      </span>
                    </div>
                    <p
                      class="mb-0 self-align-center"
                      v-if="anticipation.completedAt !== null"
                    >
                      {{
                        anticipation.completedAt | moment("DD/MM/YYYY HH:mm:ss")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="card-content">
              <div class="col-12 col-w-full mb-4 px-0">
                <div class="d-flex mb-4 small-paragraph">
                  <p
                    class="
                      font-weight-light
                      align-self-center
                      text-muted
                      mr-auto
                      mb-0
                    "
                  >
                    Valor solicitado: <br /><small
                      >{{ transactions.count }} transações</small
                    >
                  </p>
                  <h5 class="font-weight-light pull-right mb-0">
                    {{
                      anticipation.amountRequested
                        | currency("R$ ", 2, {
                          decimalSeparator: ",",
                          thousandsSeparator: ".",
                        })
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-12 col-w-full mb-4 px-0"
                v-if="
                  anticipation.status === 'approved' ||
                  anticipation.status === 'partiallyApproved'
                "
              >
                <div class="d-flex mb-4 small-paragraph">
                  <p
                    class="
                      font-weight-light
                      align-self-center
                      text-muted
                      mr-auto
                      mb-0
                    "
                  >
                    Valor aprovado:
                  </p>
                  <h3 class="font-weight-light pull-right text-orange mb-0">
                    {{
                      anticipation.amountApproved
                        | currency("R$ ", 2, {
                          decimalSeparator: ",",
                          thousandsSeparator: ".",
                        })
                    }}
                  </h3>
                </div>
              </div>
              <div class="d-block small-paragraph mb-4">
                <small
                  class="font-weight-light mr-auto mb-3"
                  v-if="anticipation.status === 'approved'"
                >
                  Pronto! Sua solicitação teve transações aprovadas e
                  programadas para o próximo repasse.
                </small>
                <small
                  class="font-weight-light mr-auto mb-3"
                  v-if="anticipation.status === 'partiallyApproved'"
                >
                  Algumas transações foram reprovadas, mas continuarão sendo
                  repassadas nas datas previstas e disponíveis para solicitações
                  futuras.
                </small>
                <small
                  class="font-weight-light text-delayed mr-auto mb-3"
                  v-if="anticipation.status === 'pending'"
                >
                  IMPORTANTE: Valores e transações podem ser alterados até o fim
                  da análise dessa solicitação.
                </small>
                <small
                  class="font-weight-light text-delayed mr-auto mb-3"
                  v-if="anticipation.status === 'reproved'"
                >
                  No momento não foi possivel atender à sua solicitação, as
                  transações continuarão sendo repassadas nas datas previstas e
                  disponíveis para solicitações futuras.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-w-full col-lg-7">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                Transações solicitadas
              </h3>
            </div>
            <hr />
            <div class="px-2">
              <table class="table">
                <thead>
                  <tr class="small-paragraph">
                    <th class="border-0 text-muted text-left">Cartão</th>
                    <th class="border-0 text-muted">NSU</th>
                    <th class="border-0 text-muted">Parcelas solicitadas*</th>
                    <th class="border-0 text-muted">Valor solicitado</th>
                    <th
                      class="border-0 text-muted"
                      v-if="
                        anticipation.status === 'approved' ||
                        anticipation.status === 'partiallyApproved'
                      "
                    >
                      Parcelas aprovadas
                    </th>
                    <th
                      class="border-0 text-muted"
                      v-if="
                        anticipation.status === 'approved' ||
                        anticipation.status === 'partiallyApproved'
                      "
                    >
                      Valor aprovado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      requestedCardTransaction, index
                    ) of transactions.requestedCardTransactions"
                    :key="index"
                  >
                    <td scope="row">
                      <span
                        class="d-flex payment-method"
                        :class="
                          requestedCardTransaction.cardTransaction.cardBrand
                        "
                      >
                        <div class="align-self-center text-left ml-2">
                          <small>{{
                            requestedCardTransaction.cardTransaction.cardFinal
                          }}</small>
                        </div>
                      </span>
                    </td>
                    <td>{{ requestedCardTransaction.cardTransaction.nsu }}</td>
                    <td>
                      {{ requestedCardTransaction.requestedInstallments }} de
                      {{
                        requestedCardTransaction.cardTransaction.installments
                      }}
                    </td>
                    <td>
                      {{
                        requestedCardTransaction.requestedAmount
                          | currency("R$ ", 2, {
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}
                    </td>
                    <td
                      v-if="
                        anticipation.status === 'approved' ||
                        anticipation.status === 'partiallyApproved'
                      "
                    >
                      <p
                        class="mb-0"
                        :class="{
                          'font-weight-bold':
                            requestedCardTransaction.approvedInstallments <
                            requestedCardTransaction.requestedInstallments,
                        }"
                      >
                        {{ requestedCardTransaction.approvedInstallments }}
                        <i
                          class="fa fa-angle-down"
                          v-if="
                            requestedCardTransaction.approvedInstallments <
                            requestedCardTransaction.requestedInstallments
                          "
                        ></i>
                      </p>
                    </td>
                    <td
                      v-if="
                        (anticipation.status === 'approved' ||
                          anticipation.status === 'partiallyApproved') &&
                        requestedCardTransaction.status !== 'reproved'
                      "
                    >
                      <p class="font-weight-bold mb-0">
                        {{
                          requestedCardTransaction.approvedAmount
                            | currency("R$ ", 2, {
                              decimalSeparator: ",",
                              thousandsSeparator: ".",
                            })
                        }}
                      </p>
                    </td>
                    <td v-if="requestedCardTransaction.status === 'reproved'">
                      <span
                        class="badge badge-pill badge-reproved tooltip-label"
                      >
                        <p class="mb-0 text-uppercase">reprovada</p>
                        <span v-if="requestedCardTransaction.note !== null">{{
                          requestedCardTransaction.note
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row justify-content-center mx-0">
                <app-paginator
                  :count="count"
                  :perPage="perPage"
                  @exchange="turnPage"
                />
              </div>
              <div class="card-content small-paragraph">
                <small>
                  * Parcelas solicitadas de parcelas totais da transação
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AnticipationError from '@/errors/AnticipationError';
import { Anticipation } from '@/models/Anticipation';
import { PaymentFunctions } from '@/models/Payment';
import { Offset } from '@/models/Offset';
import PaymentsApi from '@/services/PaymentsApi';
import AppPaginator from '@/components/theme/Paginator';
export default {
  name: 'AppShowAnticipation',
  async mounted() {
    await this.find();
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      anticipation: new Anticipation(),
      paymentFunctions: new PaymentFunctions(),
      perPage: 24,
      count: 0,
      offset: new Offset(),
      transactions: {
        cardTransactions: [],
      },
    };
  },
  methods: {
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.offset.index = inicio;
      await this.find();
    },
    async find() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      const result = await api.findAnticipation(
        this.$route.params.anticipationId
      );
      this.transactions = await api.findAnticipationTransactions(
        this.$route.params.anticipationId,
        this.offset
      );
      this.transactions.requestedCardTransactions =
        this.transactions.requestedCardTransactions.slice(0, this.perPage);
      this.count = this.transactions.count;
      if (!result.error) {
        this.anticipation = result;
      } else {
        const title = 'Ops';
        const modelError = new AnticipationError(result);
        const error = modelError.getMessage();
        this.$alert.onClose = async() =>
          this.$router.push({ name: 'anticipation' });
        this.$alert.error(title, error);
      }
      this.$bus.$emit('spinner-stop');
    },
    getStatusPayment(type) {
      return this.paymentFunctions.getStatus(type);
    },
    statusColor(type) {
      return {
        'badge-success': type === 'approved',
        'badge-pending': type === 'pending',
        'badge-canceled': type === 'partiallyApproved',
        'badge-reproved': type === 'reproved',
      };
    },
    statusName(type) {
      switch (type) {
      case 'approved':
        return 'aprovada';
      case 'pending':
        return 'em análise';
      case 'partiallyApproved':
        return 'parcialmente aprovada';
      case 'reproved':
        return 'reprovada';
      default:
        break;
      }
    },
  },
  components: {
    AppPaginator,
  },
};
</script>
