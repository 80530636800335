import { Offset } from '@/models/Offset';

export class DebitBalance {
  monthlyPayment = 0
  otherDiscounts = 0
  total = 0
}

export class Anticipation {
  id = 0
  requestDate = null
  status = ''
  totalRequested = 0
  totalAnticipated = 0
  totalReceivable = 0
  ted = 0
  debitBalance = new DebitBalance()
}

export class AnticipationFilter {
  startDate = null
  endDate = null
  status = []
  offset = new Offset()
}

export class AnticipationList {
  anticipations = [];
  count = 0;
}

export class AnticipationPermission {
  canAnticipate = false;
  canAcceptTerm = false;
}
