import CustomApiError from './CustomError';

export default class AnticipationError extends CustomApiError {
  toDictionary() {
    return {
      ANTICIPATION_UNAVAILABLE: 'A antecipação não está disponível',
      ANTICIPATION_NOT_FOUND: 'A antecipação não foi encontrada.',
      HAS_ANTICIPATION_IN_PROGRESS:
        'Já existe uma solicitação de antecipação em andamento.',
      HAS_NOT_CARD_TRANSACTIONS:
        'A solicitação de antecipação não possui transações selecionadas',
      CARD_TRANSACTION_NOT_FOUND:
        'A solicitação de antecipação possui uma transação não encontrada',
      CARD_TRANSACTION_CANCELED:
        'A solicitação de antecipação possui uma transação cancelada',
      CARD_TRANSACTION_REPROVED:
        'A solicitação de antecipação possui uma transação reprovada',
      CARD_TRANSACTION_UNAVAILABLE:
        'A solicitação de antecipação possui uma transação não disponível',
      CARD_TRANSACTION_ALREADY_ANTICIPATED:
        'A solicitação de antecipação possui uma transação que já foi antecipada',
      CARD_TRANSACTION_ALREADY_TRANSFERRED:
        'A solicitação de antecipação possui uma transação que já foi transferida',
    };
  }
}
