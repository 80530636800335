<template>
  <div class="container">
    <div class="row mb-4 m-0">
      <div class="col-sm-12 text-center">
        <h2 class="font-weight-light mb-4">TERMO DE SERVIÇO</h2>
      </div>
      <div class="row justify-content-center">
        <div class="text-left col-sm-12 col-lg-10" v-html="term"></div>
      </div>
    </div>
    <div class="row justify-content-center mx-0 mb-5">
      <div class="col-sm-12 col-lg-10">
        <div class="d-flex">
          <label class="radio-selector d-flex mx-auto">
            <input type="checkbox" v-model="acceptTerm" />
            <div class="checkbox-badge mr-3"></div>
            <h4 class="mb-3 blue-hover">Li, estou ciente e aceito os termos</h4>
          </label>
        </div>
        <div class="d-flex">
          <button
            type="button"
            @click="AcceptTerm"
            class="btn btn-orange ml-auto"
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirebaseApi from '@/services/FirebaseApi';
import PaymentsApi from '@/services/PaymentsApi';
import Alert from '@/models/Alert';
export default {
  name: 'AppTermOfService',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.termOfService();
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      term: '',
      acceptTerm: false,
    };
  },
  methods: {
    async AcceptTerm() {
      if (this.acceptTerm) {
        this.$bus.$emit('spinner-run');
        const api = new PaymentsApi();
        const result = await api.acceptTerm();
        if (!result.error) {
          this.$router.push({ name: 'anticipation' });
          location.reload();
        }
        this.$bus.$emit('spinner-stop');
      } else {
        var alert = new Alert();
        alert.title = 'Atenção';
        alert.message = 'Para prosseguir com a antecipação é necessário aceitar os termos de serviço!';
        this.$bus.$emit('alert-warning', alert);
      }
    },
    async termOfService() {
      const api = new FirebaseApi();
      const result = await api.getTerm();
      this.term = result;
    },
  },
};
</script>
