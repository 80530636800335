<template>
  <div>
    <div v-if="isAccountActive">
      <app-term-of-service v-if="anticipationPermission.canAcceptTerm" />
      <div v-if="anticipationPermission.canAnticipate">
        <div
          v-if="
            (anticipation === null &&
              anticipationPermission.hasCardTransaction) ||
            (anticipation === null && !whoAmI.account.transferPlan.anticipated)
          "
        >
          <div class="header-box pt-5 border-0">
            <div class="row m-0">
              <WhiteLabel class="m-auto">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      href="#anticipation"
                      class="nav-link active"
                      data-toggle="tab"
                      >Antecipação</a
                    >
                  </li>

                  <li class="nav-item">
                    <a href="#history" class="nav-link" data-toggle="tab"
                      >Histórico</a
                    >
                  </li>
                </ul>
              </WhiteLabel>
            </div>
          </div>
          <form id="formOrder" class="tab-content" autocomplete="off">
            <div id="anticipation" class="tab-pane show active">
              <div id="anticipation-collapse" data-parent="#formOrder">
                <app-anticipation-value
                  :permission="userAnticipationPermission"
                />
              </div>
            </div>
            <div id="history" class="tab-pane">
              <div id="history-collapse" data-parent="#formOrder">
                <app-anticipation-history
                  :permission="userAnticipationPermission"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        v-if="
          anticipation !== null ||
          (whoAmI.account.transferPlan.anticipated &&
            !anticipationPermission.hasCardTransaction)
        "
      >
        <app-anticipation-history />
      </div>
    </div>
    <div v-if="!isAccountActive">
      <app-anticipation-history />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/models/Alert';
import AppAnticipationValue from './new-anticipation/AnticipationValue';
import AppAnticipationHistory from './new-anticipation/AnticipationHistory';
import AppTermOfService from './TermOfService';
import PaymentsApi from '@/services/PaymentsApi';
import { PermissionList } from '@/models/Permission';
import {
  Anticipation,
  AnticipationFilter,
  AnticipationList,
  AnticipationPermission,
} from '@/models/Anticipation';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppNewAnticipation',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.canAntecipate();
    await this.getAnticipationInProgress();
    await this.askWhoAmI();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      anticipation: null,
      anticipationPermission: new AnticipationPermission(),
      permissionList: new PermissionList(),
    };
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    anticipationPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.anticipation'
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    userAnticipationPermission() {
      if (
        (this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'edit' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),
    async getAnticipationInProgress() {
      const api = new PaymentsApi();
      let result = await api.getAnticipationInProgress();
      if (!result.error) {
        this.anticipation = result;
      }
    },
    async canAntecipate() {
      const api = new PaymentsApi();
      var result = await api.canAnticipate();
      this.anticipationPermission = result;
      if (
        !this.anticipationPermission.canAcceptTerm &&
        !this.anticipationPermission.canAnticipate
      ) {
        var alert = new Alert();
        alert.title = 'Atenção';
        alert.message = `<p class="text-justify">Você ainda não possui os requisitos necessários para realizar antecipação. Para solicitar antecipação de transações, você deve atender os seguintes critérios:</p>
                        <ol class="text-left">
                          <li>Estar cadastrado no sistema há pelo menos <b>2 meses</b>.</li>
                          <li>Ter realizado ao menos 6 transações aprovadas e não ter transações contestadas no período ou vender utilizando a completinha.</li>
                        </ol>`;
        this.$bus.$emit('alert-warning-html', alert);
        this.$router.go(-1);
      }
    },
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
  },
  components: {
    AppAnticipationValue,
    AppAnticipationHistory,
    AppTermOfService,
    WhiteLabel,
  },
};
</script>
