<template>
  <div>
    <div class="header-box mt-0 mb-4 pb-4">
      <div class="container">
        <div class="d-flex pt-4 mb-3">
          <div class="d-block text-right mx-auto text-center">
            <h4 class="font-weight-light mb-0 text-muted">
              Valor disponível para antecipação:
            </h4>
            <WhiteLabel>
              <h1 class="font-weight-light text-orange mb-1">
                {{
                  transferBalance.amountAnticipable !== null
                    ? transferBalance.amountAnticipable
                    : 0
                      | currency("R$ ", 2, {
                        decimalSeparator: ",",
                        thousandsSeparator: ".",
                      })
                }}
              </h1>
            </WhiteLabel>
          </div>
        </div>
        <div class="d-flex" v-if="transactionsAvailable.count !== 0">
          <WhiteLabel class="m-auto">
            <button
              type="submit"
              class="mx-auto btn btn-outline-orange btn-pill px-4"
              @click.prevent="anticipateAllTransactions()"
              v-if="permission"
            >
              Antecipar tudo
            </button>
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="container">
      <form @submit.prevent="sendAnticipation()" autocomplete="off">
        <div class="row mb-3" v-if="transactionsAvailable.count !== 0">
          <div class="col-sm-12">
            <h3 class="font-weight-light mb-0 pr-4">
              Selecione as transações que deseja antecipar:
            </h3>
          </div>
        </div>
        <div class="row mb-4" v-if="selectedTransactionList.length > 0">
          <div class="col d-flex small-paragraph mb-3 mb-md-0">
            <div class="text-left mr-auto">
              <WhiteLabel>
                <h2 class="font-weight-light text-orange text-nowrap mb-0">
                  {{
                    anticipationLiquid
                      | currency("R$ ", 2, {
                        decimalSeparator: ",",
                        thousandsSeparator: ".",
                      })
                  }}
                </h2>
              </WhiteLabel>
              <small class="mb-0"
                >{{ selectedTransactionList.length }}/{{
                  transactionsAvailable.count
                }}
                transações selecionadas</small
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 align-self-center">
            <WhiteLabel>
              <button
                type="submit"
                class="btn btn-orange w-100"
                :disabled="selectedTransactionList.length === 0"
              >
                Antecipar selecionadas
              </button>
            </WhiteLabel>
          </div>
        </div>
      </form>
      <div class="row mb-5">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="(transaction, index) of transactionsAvailable.cardTransactions"
          :key="index"
        >
          <label class="radio-selector w-100">
            <div
              class="card table-card border-badge"
              :class="
                transaction.online ? 'border-left-pinpad' : 'border-left-typed'
              "
            >
              <div class="card-content">
                <div class="d-flex mb-3">
                  <div class="pull-left text-left d-block mr-auto">
                    <span
                      class="payment-method brand-card d-table"
                      :class="transaction.cardBrand"
                    ></span>
                    <small>**** **** **** {{ transaction.cardFinal }}</small>
                  </div>
                  <div class="text-right pull-right">
                    <h6 class="mb-0 font-weight-light text-muted">
                      Valor a ser antecipado:
                    </h6>
                    <h3 class="mb-0 font-weight-light">
                      {{
                        transaction.amountAnticipable
                          | currency("R$ ", 2, {
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}
                    </h3>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="pull-left text-left mr-auto">
                    <h6 class="font-weight-light mb-0 text-muted">
                      Data da transação:
                    </h6>
                    <p class="mb-0">
                      {{ transaction.paidAt | moment("DD/MM/YYYY HH:mm") }}
                    </p>
                  </div>
                  <div class="pull-right text-right">
                    <h6 class="font-weight-light mb-0 text-muted">
                      Valor total:
                    </h6>
                    <p class="mb-0">
                      {{
                        transaction.amountPaid
                          | currency("R$ ", 2, {
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}
                    </p>
                    <small
                      >{{ transaction.installments }} x
                      {{
                        transaction.installmentValue
                          | currency("R$ ", 2, {
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}</small
                    >
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <div class="pull-left mr-auto">
                    <h6 class="font-weight-light align-self-center mb-0">
                      NSU: {{ transaction.nsu }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="pull-left mr-auto">
                    <span
                      class="badge badge-pill badge-outline border-darkgray"
                    >
                      <small class="text-uppercase mr-auto">{{
                        transaction.online ? "Pinpad" : "Digitada"
                      }}</small>
                    </span>
                  </div>
                  <div class="pull-right" v-if="permission">
                    <WhiteLabel>
                      <input
                        type="checkbox"
                        v-model="selectedTransactionListDisplay[index]"
                        @click="clickCheckbox(index, transaction)"
                      />

                      <div class="checkbox-badge"></div>
                    </WhiteLabel>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div v-if="transactionsAvailable.count === 0">
        <div class="text-center">
          <h4>Nenhuma transação encontrada.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="transactionsAvailable.count"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsApi from '@/services/PaymentsApi';
import { AnticipationFilter, AnticipationList } from '@/models/Anticipation';
import AnticipationError from '@/errors/AnticipationError';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppAnticipationValue',

  components: {
    AppPaginator,
    WhiteLabel,
  },

  props: {
    permission: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    perPage: 12,
    count: 0,
    selectedTransactionList: [],
    selectedTransactionListDisplay: [],
    transactionsAvailable: new AnticipationList(),
    anticipationFilter: new AnticipationFilter(),
    anticipationTotal: 0,
    anticipationLiquid: 0,
    selectAll: false,
    transferBalance: {
      nextTransfer: {
        amount: 0,
        date: '',
      },
      anticipatedTransfer: {
        amount: 0,
        amountToReceive: 0,
      },
      creditAmount: 0,
      debitAmount: 0,
    },
  }),

  watch: {
    selectAll: async function() {
      if (this.selectAll) {
        this.selectedTransactionList = [];
        this.anticipationTotal = 0;
        this.anticipationLiquid = 0;
        const api = new PaymentsApi();
        const result = await api.getTransactionsAvailable(
          this.anticipationFilter
        );
        for (var i = 0; i < result.cardTransactions.length; i++) {
          this.selectedTransactionList.push(result.cardTransactions[i]);
          this.anticipationTotal =
            this.anticipationTotal + result.cardTransactions[i].amountPaid;
          this.anticipationLiquid =
            this.anticipationLiquid +
            result.cardTransactions[i].amountAnticipable;
          for (var j = 0; j < this.selectedTransactionList.length; j++) {
            if (
              result.cardTransactions[i].nsu ===
              this.selectedTransactionList[j].nsu
            ) {
              this.selectedTransactionListDisplay[i] = true;
            }
          }
        }
      }
    },
  },

  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getTransactions();
    await this.getTransferBalance();
    this.mountCheckboxGrid();
    this.$bus.$emit('spinner-stop');
  },

  methods: {
    async getTransactions() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      this.transactionsAvailable = await api.getTransactionsAvailable(
        this.anticipationFilter
      );
      this.transactionsAvailable.cardTransactions =
        this.transactionsAvailable.cardTransactions.slice(0, this.perPage);
      this.$bus.$emit('spinner-stop');
    },

    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.anticipationFilter.offset.index = inicio;
      await this.getTransactions();
      this.selectedTransactionListDisplay = [];
      this.mountCheckboxGrid();
      if (this.selectedTransactionList.length !== 0) {
        for (
          var i = 0;
          i < this.transactionsAvailable.cardTransactions.length;
          i++
        ) {
          for (var j = 0; j < this.selectedTransactionList.length; j++) {
            if (
              this.transactionsAvailable.cardTransactions[i].nsu ===
              this.selectedTransactionList[j].nsu
            ) {
              this.selectedTransactionListDisplay[i] = true;
            }
          }
        }
      }
    },

    clickCheckbox(index, transaction) {
      if (!this.selectedTransactionListDisplay[index]) {
        this.selectedTransactionListDisplay[index] = true;
        this.selectedTransactionList.push(transaction);
        this.anticipationTotal =
          this.anticipationTotal + transaction.amountPaid;
        this.anticipationLiquid =
          this.anticipationLiquid + transaction.amountAnticipable;
      } else {
        this.selectedTransactionListDisplay[index] = false;
        this.selectAll = false;
        for (var i = 0; i < this.selectedTransactionList.length; i++) {
          if (this.selectedTransactionList[i].nsu === transaction.nsu) {
            this.selectedTransactionList.splice(i, 1);
            this.anticipationTotal =
              this.anticipationTotal - transaction.amountPaid;
            this.anticipationLiquid =
              this.anticipationLiquid - transaction.amountAnticipable;
          }
        }
      }
    },

    mountCheckboxGrid() {
      for (var i = 0; i < this.perPage; i++) {
        this.selectedTransactionListDisplay.push(false);
      }
    },

    async sendAnticipation() {
      const result = { cardTransactions: [] };
      for (var i = 0; i < this.selectedTransactionList.length; i++) {
        result.cardTransactions.push(this.selectedTransactionList[i].nsu);
      }

      this.$alert.question(
        'Confirmar a solicitação de antecipação?',
        'Caso você confime não será possível desfazer essa solicitação.'
      );
      this.$alert.onConfirm = async() => {
        const api = new PaymentsApi();
        await api.requestSelectedTransactions(result).then(async(response) => {
          if (response.error) {
            const modelError = new AnticipationError(response);

            if (
              response.error ==
              'Servico em manutencao. Solicitacao de antecipacao temporariamente bloqueada'
            ) {
              this.$alert.error('Ops!', response.error);

              return;
            }

            this.$alert.error('Ops', modelError.getMessage());
          } else {
            this.$alert.onClose = async() => window.location.reload();
            this.$alert.info('Pronto', 'A sua solicitação foi realizada.');
          }
          this.$bus.$emit('spinner-stop');
        });
      };
    },

    async anticipateAllTransactions() {
      this.$alert.question(
        'Antecipar tudo',
        'Deseja realmente antecipar todas as transações?'
      );
      this.$alert.onConfirm = async() => {
        const api = new PaymentsApi();
        var result = await api
          .anticipateAllTransactions()
          .then(async(response) => {
            if (response.error) {
              const modelError = new AnticipationError(response);

              if (
                response.error ==
                'Servico em manutencao. Solicitacao de antecipacao temporariamente bloqueada'
              ) {
                this.$alert.error('Ops!', response.error);

                return;
              }
              this.$alert.error('Ops!', modelError.getMessage());
            } else {
              this.$alert.onClose = async() => window.location.reload();
              this.$alert.info('Pronto', 'A sua solicitação foi realizada.');
            }
          });
      };
    },

    async getTransferBalance() {
      const api = new PaymentsApi();
      var result = await api.getTransferBalance();
      this.transferBalance = result;
    },
  },
};
</script>
